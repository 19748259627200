import axios from 'axios'

export const getJwtToken = async (oamCode) => {
  try {
    const url = `${process.env.REACT_APP_URL_LAMBDA}/portal/login`
    const payload = { code: oamCode }
    const data = await axios.post(url, payload)
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export const validateJwtToken = async (jwt) => {
  try {
    const url = `${process.env.REACT_APP_URL_LAMBDA}/portal/validate`
    const payload = { accessToken: jwt }
    const data = await axios.post(url, payload)
    return data
  } catch (error) {
    throw new Error(error)
  }
}
