import { IframeContainer, StyledIframe } from './styles'

const WebchatFrame = ({ url }) => {
  localStorage.removeItem('oauthRetries')
  localStorage.removeItem('assetId')
  return (
    <IframeContainer referrerPolicy="no-referrer" x-frame-options="same-origin">
      <StyledIframe src={url} title="Fullscreen Iframe" allowFullScreen />
    </IframeContainer>
  )
}

export default WebchatFrame
