import React, { useEffect, useState, useMemo } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import WebchatFrame from './pages/WebchatFrame'
import ErrorPage from './pages/NotFound'
import { getJwtToken, validateJwtToken } from './utils/getJwtToken'

const MAX_RETRIES = 3

const App = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [clientJwt, setClientJwt] = useState(null)
  const [hasPermission, setHasPermission] = useState(false)
  const [retries, setRetries] = useState(() =>
    parseInt(localStorage.getItem('oauthRetries') || '0', 10)
  )

  const urlOauth = useMemo(() => {
    return new URL(
      `${process.env.REACT_APP_TIM_API_URL}/authorize?redirect_uri=${process.env.REACT_APP_LIGO_REDIRECT_URI}&response_type=code&client_id=${process.env.REACT_APP_LIGO_CLIENT_ID}&scope=${process.env.REACT_APP_LIGO_SCOPE}`
    )
  }, [])

  useEffect(() => {
    if (retries >= MAX_RETRIES) {
      navigate('/error')
      return
    }

    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get('code')
    const assetId = searchParams.get('assetId')

    if (assetId) {
      localStorage.setItem('assetId', assetId)
    }

    if (code) {
      getJwtToken(code)
        .then(async (response) => {
          const { successful, oracle_token_attrs_retrieval } =
            response?.data?.message

          if (successful && oracle_token_attrs_retrieval) {
            const assetIdStorage = localStorage.getItem('assetId')
            if (assetIdStorage) {
              oracle_token_attrs_retrieval.assetId = assetIdStorage
            }

            setClientJwt(oracle_token_attrs_retrieval)
          } else {
            incrementRetries()
            setHasPermission(false)
            redirectToOAuth()
          }
        })
        .catch(() => {
          incrementRetries()
          setHasPermission(false)
          redirectToOAuth()
        })
    } else {
      incrementRetries()
      setHasPermission(false)
      redirectToOAuth()
    }
  }, [location.search])

  useEffect(() => {
    if (clientJwt?.accesToken) {
      validateJwtToken(clientJwt.accesToken)
        .then(() => setHasPermission(true))
        .catch(() => {
          incrementRetries()
          setHasPermission(false)
          redirectToOAuth()
        })
    }
  }, [clientJwt])

  const incrementRetries = () => {
    const newRetries = retries + 1
    setRetries(newRetries)
    localStorage.setItem('oauthRetries', newRetries.toString())
  }

  const redirectToOAuth = () => {
    window.location.href = urlOauth
  }

  const urlWebchat = useMemo(() => {
    if (hasPermission && clientJwt) {
      const { accesToken } = clientJwt
      const assetIdParam = clientJwt?.assetId
        ? `?assetId=${clientJwt.assetId}&`
        : '?'
      return `${process.env.REACT_APP_WEBCHAT_URL}/${process.env.REACT_APP_BOT_ID}${assetIdParam}canal_origem=chatweb&accessToken=${accesToken}`
    }
    return ''
  }, [hasPermission, clientJwt])

  return (
    <Routes>
      <Route
        path="/"
        element={
          hasPermission && urlWebchat ? <WebchatFrame url={urlWebchat} /> : null
        }
      />
      <Route path="/error" element={<ErrorPage />} />
    </Routes>
  )
}

export default App
