import React from 'react'
import notFound from '../../assets/not_found.svg'
import * as S from './styles'

const NotFound = () => {
  localStorage.removeItem('oauthRetries')
  localStorage.removeItem('assetId')
  return (
    <S.Container>
      <h4 variant="h4-normal" className="not-found-descripton">
        Desculpe, mas o Bot está desativado ou o endereço incorreto.
      </h4>
      <img src={notFound} alt="" className="image" />
    </S.Container>
  )
}

export default NotFound
