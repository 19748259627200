import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  background-color: #f3f5f9;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 100px;

  .not-found-descripton {
    max-width: 362px;
  }
`;
